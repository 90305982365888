import React, { useState, useEffect } from "react";
import { Container, Form, Button, OverlayTrigger, Tooltip, InputGroup, Navbar } from 'react-bootstrap';
import { auth, generateShortLink, SECRET_KEY, sendEmailVerificationAgain } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';
import cheerio from 'cheerio';
import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import "./home.css";
import "./../../Global/shared-styles.css"
import { VscAccount } from "react-icons/vsc";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { firebaseConfig } from "../../firebase.js";
import LoadingSceen from "../Loading/Loading.js";
import { GoSignOut } from "react-icons/go";
import { toast, ToastContainer } from 'react-toastify';
import { PiStarFourFill } from "react-icons/pi";
import { isMobile } from 'react-device-detect';
import MobileNotSupport from "../MobileNotSupport/mobilesupport.jsx";
import 'react-toastify/dist/ReactToastify.css';
import { sha256 } from 'js-sha256';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const isURL = (text) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const isSecureURL = (text) => {
    const urlPattern = /^(https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const checkValidation = (linkTitle, originalLink, isCustomLinkSwitchOn, customLink, setIsCreateShortLinkBtnLoading) => {
    if (linkTitle == null || originalLink == null) {
        toast.error('Link Title or Original Link can not be empty!');
    } else if (!isURL(originalLink)) {
        toast.error('Original Link is missing!');
    } else if (!isSecureURL(originalLink)) {
        toast.error('Original Link is not secure!');
    } else if (isCustomLinkSwitchOn && customLink == null) {
        toast.error('Custom Link can not be empty!');
    } else if (isCustomLinkSwitchOn && customLink.length > 16) {
        toast.error('The custom link must not exceed 16 characters in length');
    } else {
        setIsCreateShortLinkBtnLoading(true);
        generateShortLink(linkTitle, originalLink, customLink).then((res) => {
            console.log(res)
            if (res.code === 200) {
                setIsCreateShortLinkBtnLoading(false);
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
                localStorage.setItem("generatedURL", res.customid);
            } else {
                setIsCreateShortLinkBtnLoading(false);
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/transmissionstatus';
        })

    }
}

const handleCountdownComplete = () => {
    auth.signOut()
        .then(() => {
            // Logout successful
            console.log('User logged out.');
            localStorage.removeItem('ue');
            localStorage.clear();
            window.localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            // Handle logout error
            console.error('Error logging out:', error);
        });
};

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <handleCountdownComplete />;
    } else {
        // Render a countdown
        return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
    }
};

export default function Home() {

    const currentYear = new Date().getFullYear();

    const [linkTitle, setLinkTitle] = useState("");
    const [originalLink, setOriginalLink] = useState("");
    const [customLink, setCustomLink] = useState("");
    const [characterCount, setCharacterCount] = useState(0);

    const [iamtxtonly, setIamtxtonly] = useState('');
    const [verifytxtonly, setVerifytxtonly] = useState('');
    const [tontxtonly, setTontxtonly] = useState('');
    const [govagencytxtonly, setGovagencytxtonly] = useState('');

    const [isUserEmailVerified, setIsUserEmailVerified] = useState(false);

    const [dataFatchComplete, setDataFatchComplete] = useState(false);
    const [currentuser, setCurrentUser] = useState(null);

    const [isAiBtnLoading, setIsAiBtnLoading] = useState(false);

    const [isCreateShortLinkBtnLoading, setIsCreateShortLinkBtnLoading] = useState(false);

    const [lastActivity, setLastActivity] = useState(Date.now());

    const handleActivity = () => {
        setLastActivity(Date.now());
    };

    useEffect(() => {
        // Listen for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
        };
    }, []);

    const dataciphertext = JSON.parse(localStorage.getItem('ue'));

    // Decrypt
    const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
    const originalTextofEmail = bytes.toString(CryptoJS.enc.Utf8);

    const [user, cusloading, error] = useAuthState(auth);

    const handleCustomLinkInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 16) {
            setCustomLink(inputValue);
            setCharacterCount(inputValue.length);
        }
    };

    useEffect(() => {
        if (!cusloading && !user) {
            window.location.href = '/';
        }
    }, [user, cusloading]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                setCurrentUser(user);
            } else {
                // User is signed out.
                setCurrentUser(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        // Fetch user document with specific email from Firestore
        const fetchUser = async () => {
            const querySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', originalTextofEmail)));
            querySnapshot.forEach((doc) => {
                localStorage.setItem('ton', JSON.stringify(doc.data().individual));
                localStorage.setItem('verify', JSON.stringify(doc.data().verify));
                localStorage.setItem('iam', JSON.stringify(doc.data().isAdmin));

                const iam = doc.data().isAdmin;
                const verify = doc.data().verify;
                const ton = doc.data().individual;
                const govagency = doc.data().govagency;

                setIamtxtonly(iam ? 'Super Admin' : 'Admin');
                setVerifytxtonly(verify ? 'https://cdn.egov.nugmyanmar.org/assets/state/verifygov.png' : '');
                setTontxtonly(ton ? 'Individual' : 'Organization');
                if (ton === false) {
                    setGovagencytxtonly(govagency);
                } else {
                    setGovagencytxtonly('');
                }
                setIsUserEmailVerified(auth.currentUser.emailVerified ? true : false);
                setDataFatchComplete(true);
            });
        };

        fetchUser();
    }, [originalTextofEmail]); // Dependency array

    function getGravatarURL(email) {
        const address = String(email).trim().toLowerCase();
        const hash = sha256(address);
        return `https://www.gravatar.com/avatar/${hash}/s=100?d=retro`;
    }

    const handleFetchTitle = async (url) => {
        setIsAiBtnLoading(true);
        setLinkTitle('');
        if (url === null) {
            toast.error('Original Link is missing!');
        } else if (!isURL(url)) {
            toast.error('Original Link is missing!');
        } else if (isSecureURL(url) === false) {
            toast.error('Original Link is not secure!');
        } else {
            try {
                const proxyUrl = 'https://api.allorigins.win/raw?url=';
                const response = await axios.get(proxyUrl + url);
                const html = response.data;
                const $ = cheerio.load(html);
                const pageTitle = $('head title').text();
                setLinkTitle(pageTitle);
                console.log(linkTitle);
            } catch (error) {
                console.error('Error fetching URL:', error);
            }
        }
        setIsAiBtnLoading(false);

    };

    const iam = JSON.parse(localStorage.getItem('iam'));
    const verify = JSON.parse(localStorage.getItem('verify'));
    const ton = JSON.parse(localStorage.getItem('ton'));

    const [isCustomLinkSwitchOn, setIsCustomLinkSwitchOn] = useState(false);

    let imgSrc = getGravatarURL(originalTextofEmail);

    return (
        <>
            {isMobile ? <MobileNotSupport /> :
                dataFatchComplete ? (
                    <>

                        <div className="logincontainer">

                            {isUserEmailVerified ? (
                                <div className='d-flex justify-content-between logoutbtn'>

                                    <div>
                                        <Countdown
                                            className="m-3"
                                            // date={Date.now() + 300000}
                                            date={lastActivity + 300000}
                                            renderer={renderer}
                                            onComplete={handleCountdownComplete}
                                        />
                                        <button className='btn btn-outline-secondary m-3 customLogoutBtnBg customLogoutBtn' onClick={(event) => {
                                            event.preventDefault(); // Cancel the default browser behavior

                                            // Perform logout here
                                            auth.signOut()
                                                .then(() => {
                                                    // Logout successful
                                                    console.log('User logged out.');
                                                    localStorage.removeItem('ue');
                                                    localStorage.clear();
                                                    window.location = '/';
                                                })
                                                .catch((error) => {
                                                    // Handle logout error
                                                    console.error('Error logging out:', error);
                                                });
                                        }}>Logout<GoSignOut className="m-1" /></button>
                                    </div>

                                    <div className="d-flex uinfogp">

                                        <div className="myaccountbtn">
                                            <OverlayTrigger
                                                key={'Adminpanel'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        Profile
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    variant="outline-secondary"
                                                    className="myaccount btn rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                    onClick={() => { window.location.href = '/myaccount.adm'; }}
                                                >
                                                    <img src={imgSrc} className="profileimg" />
                                                    {/* <VscAccount width={40} height={40} /> */}
                                                </button>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="currentEmail">
                                            {/* <span className="iamtxt" onClick={() => window.location.href = '/go.usercreation.adm'}>{iamtxtonly}</span> */}
                                            <OverlayTrigger
                                                key={'Adminpanel'}
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-bottom`}>
                                                        Admin Panel
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    className={`iamtxt btn ${iamtxtonly === 'Super Admin' ? 'btn-outline-secondary' : 'text-muted'}`}
                                                    onClick={() => { if (iamtxtonly === 'Super Admin') window.location.href = '/go.usercreation.adm'; }}
                                                    disabled={iamtxtonly === 'Admin'}
                                                >
                                                    {iamtxtonly}
                                                </button>
                                            </OverlayTrigger>
                                            {originalTextofEmail}
                                        </div>

                                        <div className="currentEmail">{tontxtonly}</div>
                                        {govagencytxtonly && <div className="currentEmail">{govagencytxtonly}</div>}

                                        <OverlayTrigger
                                            key={'verified'}
                                            placement={'right'}
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    Verified <strong>Gov Account</strong>.
                                                </Tooltip>
                                            }
                                        >
                                            <img src={verifytxtonly} height={50} className="verifygovlogo" />

                                        </OverlayTrigger>

                                    </div>

                                </div>
                            ) : (<div className='d-flex justify-content-between logoutbtn'>

                                <div>
                                    <Countdown
                                        className="m-3"
                                        date={Date.now() + 300000}
                                        renderer={renderer}
                                        onComplete={handleCountdownComplete}
                                    />
                                    <button className='btn btn-outline-secondary m-3 customLogoutBtnBg customLogoutBtn' onClick={(event) => {
                                        event.preventDefault(); // Cancel the default browser behavior

                                        // Perform logout here
                                        auth.signOut()
                                            .then(() => {
                                                // Logout successful
                                                console.log('User logged out.');
                                                localStorage.removeItem('ue');
                                                localStorage.clear();
                                                window.localStorage.clear();
                                                window.location = '/';
                                            })
                                            .catch((error) => {
                                                // Handle logout error
                                                console.error('Error logging out:', error);
                                            });
                                    }}>Logout<GoSignOut className="m-1" /></button>
                                </div>

                                <div className='navbar'>
                                    <Navbar.Brand href="/home.adm">
                                        <img
                                            src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                                            width="40"
                                            height="40"
                                            className="d-inline-block align-center"
                                            alt="Go.NUG Logo"
                                        />
                                    </Navbar.Brand>
                                    <span className='slug text-muted'>/</span>
                                    <span className="slug text-muted">Email Verification</span>

                                </div>

                            </div>)}

                            <ToastContainer
                                position="bottom-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />

                            {isUserEmailVerified ? (

                                <Container className='align-items-center d-flex navless-container containerheight'>
                                    <Container className='align-items-center d-flex navless-container containerheight'>

                                        <div className="m-auto formwidth">
                                            <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />

                                            <Form.Group className="my-3" controlId="formLinkTitle">
                                                <Form.Label>Link Title *</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        disabled={!verifytxtonly}
                                                        type="text"
                                                        value={linkTitle}
                                                        onChange={e => setLinkTitle(e.target.value)}
                                                    />
                                                    <Button
                                                        variant="outline-dark"
                                                        onClick={() => handleFetchTitle(originalLink)}
                                                        disabled={!originalLink}
                                                        className="AiBtn"
                                                    >
                                                        {isAiBtnLoading ?
                                                            <PiStarFourFill
                                                                className="pistar-icon"
                                                                style={{ animation: 'spin 1s linear infinite', marginTop: '-0.2rem', color: 'red' }}
                                                            />
                                                            :
                                                            <PiStarFourFill
                                                                className="pistar-icon"
                                                                style={originalLink && isURL(originalLink) & isSecureURL(originalLink) ? { animation: 'appear 1s ease-out', marginTop: '-0.1rem', color: 'red' } : {}}
                                                            />
                                                        }
                                                    </Button>
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group className="my-3" controlId="formLinkSlug">
                                                <Form.Label>Original Link * <span className="smalltextsize"> All of link are should be start with <span className="httpscolor">https://</span></span></Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        disabled={!verifytxtonly}
                                                        type="url"
                                                        onChange={e => setOriginalLink(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </Form.Group>

                                            <div className="d-flex gap-2 text-align-center">
                                                <Form.Check className="switchBorder" type="switch" id="custom-switch" label="Custom Link" disabled={!verifytxtonly} checked={isCustomLinkSwitchOn} onChange={() => setIsCustomLinkSwitchOn(!isCustomLinkSwitchOn)} />
                                            </div>

                                            {isCustomLinkSwitchOn && (
                                                <Form.Group className="my-3" controlId="formCustomLinkSlug">
                                                    <div className="d-flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Form.Label>Custom Link *
                                                        </Form.Label>
                                                        <p style={characterCount > 9 ? (characterCount === 16 ? { color: 'red' } : { color: 'orange' }) : { color: 'gray' }}>{characterCount} / 16</p>
                                                    </div>
                                                    <InputGroup className="mb-2" disabled={!verifytxtonly}>
                                                        <InputGroup.Text className="text-muted">go.nugmyanmar.org/</InputGroup.Text>
                                                        <Form.Control type="url" value={customLink} onChange={handleCustomLinkInputChange} />
                                                    </InputGroup>
                                                </Form.Group>
                                            )}

                                            <div className="d-grid gap-2">
                                                <Button
                                                    variant="danger"
                                                    type="submit"
                                                    className='loginBtn'
                                                    disabled={!verifytxtonly}
                                                    onClick={() =>
                                                        checkValidation(linkTitle, originalLink, isCustomLinkSwitchOn, customLink, setIsCreateShortLinkBtnLoading)
                                                    }>
                                                    {isCreateShortLinkBtnLoading ?
                                                        'Loading...'
                                                        :
                                                        'Generate Shorten Link'
                                                    }
                                                </Button>
                                            </div>

                                            <hr className="m-y:6" />

                                            <div className="d-flex">
                                                <Button
                                                    variant="outline-secondary"
                                                    type="submit"
                                                    className='loginBtn deleteBtn'
                                                    style={{
                                                        width: '70%',
                                                        marginRight: '0.5rem'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault(); // Cancel the default browser behavior
                                                        window.location = '/lists.adm';
                                                    }}>
                                                    View Created Shorten Links
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    type="submit"
                                                    className='loginBtn deleteBtn'
                                                    style={{
                                                        width: '30%',
                                                        marginLeft: '0.5rem'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault(); // Cancel the default browser behavior
                                                        window.location = '/whois';
                                                    }}>
                                                    WHOIS
                                                </Button>
                                            </div>

                                        </div>
                                    </Container >
                                </Container>

                            ) : (
                                <Container className='align-items-center d-flex navless-container containerheight'>
                                    <div className="m-auto formwidth">
                                        <h2>You are not verified on your email</h2>
                                        <p>Please verify first and click enter reload button</p>
                                        <Button variant="primary" className="me-3" onClick={() => window.location.reload()}>
                                            Reload
                                        </Button>

                                        <Button variant="secondary" onClick={() => {
                                            sendEmailVerificationAgain()
                                                .then((response) => {
                                                    if (response.code === 200) {
                                                        toast.success('Verification email sent!');
                                                    } else {
                                                        toast.error('Failed to send verification email');
                                                    }
                                                })
                                            // .catch((error) => {
                                            //     console.error(error);
                                            //     toast('An error occurred');
                                            // });
                                        }}>
                                            Resend Verification Email
                                        </Button>
                                    </div>
                                </Container>
                            )}
                            <div className="footer" hidden={isCustomLinkSwitchOn}>
                                <p className="text-muted">© {currentYear} NUG Myanmar </p>
                            </div>
                        </div >
                    </>
                ) : (
                    <LoadingSceen />
                )
            }

        </>

    )
}