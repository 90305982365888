import React, { useState } from "react";
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { auth, deleteShortLink } from "../../firebase.js";
import Countdown from 'react-countdown';
import "./delete.css";

const isURL = (text) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const isURLStartsWith = (url, prefix) => {
    return url.startsWith(prefix);
};

const checkValidation = (shortenLink, originalLink, email, setModalMessage, setShowModal) => {
    if (shortenLink == null || originalLink == null) {
        setModalMessage('Shorten Link or Original Link can not be empty!');
        setShowModal(true);
        // alert('Shorten Link or Original Link can not be empty!')
    } else if (!isURLStartsWith(shortenLink, "https://go.nugmyanmar.org/")) {
        setModalMessage('Shorten Link must be start with "https://go.nugmyanmar.org/" only!');
        setShowModal(true);
        // alert('Shorten Link must be start with "https://go.nugmyanmar.org/" only!')
    } else if (!isURL(originalLink) && !isURL(shortenLink)) {
        setModalMessage('Original Link and Shorten Link are missing!');
        setShowModal(true);
        // alert('Original Link and Shorten Link are missing!')
    } else if (!isURL(originalLink)) {
        setModalMessage('Original Link is missing!');
        setShowModal(true);
        // alert('Original Link is missing!')
    } else if (!isURL(shortenLink)) {
        setModalMessage('Shorten Link is missing!');
        setShowModal(true);
        // alert('Shorten Link is missing!')
    } else {
        deleteShortLink(shortenLink, originalLink, email).then((res) => {
            setModalMessage(res.message);
            setShowModal(true);
            if (setShowModal) {
                setTimeout(() => {
                    window.location = '/home.adm';
                }, 3000);
            }
            // alert(res.message);
            // window.location = '/home.adm';
        })
    }
}

const handleCountdownComplete = () => {
    auth.signOut()
        .then(() => {
            // Logout successful
            console.log('User logged out.');
            localStorage.removeItem('ue');
            localStorage.clear();
            window.localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            // Handle logout error
            console.error('Error logging out:', error);
        });
};

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <handleCountdownComplete />;
    } else {
        // Render a countdown
        return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
    }
};

export default function Delete() {

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [shortenLink, setShortenLink] = useState("");
    const [originalLink, setOriginalLink] = useState("");
    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{modalMessage}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="logincontainer">
                <div className='d-flex logoutbtn'>
                    <button className='btn btn-outline-secondary m-3' onClick={(event) => {
                        event.preventDefault(); // Cancel the default browser behavior
                        window.location = '/home.adm';
                    }}>Back to Home</button>
                    <Countdown
                        className="m-3"
                        date={Date.now() + 300000}
                        renderer={renderer}
                        onComplete={handleCountdownComplete}
                    />
                </div>
                <Container className=' align-items-center d-flex navless-container'>

                    <div className="m-auto formwidth">
                        <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />

                        <Form.Group className="my-3" controlId="formLinkTitle">
                            <Form.Label>Shorten Link * <span className="smalltextsize"> All of link are should be start with <span className="httpscolor">https://go.nug...</span></span></Form.Label>
                            <Form.Control type="url" onChange={e => setShortenLink(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="my-3" controlId="formLinkSlug">
                            <Form.Label>Original Link * <span className="smalltextsize"> All of link are should be start with <span className="httpscolor">https://</span></span></Form.Label>
                            <Form.Control type="url" onChange={e => setOriginalLink(e.target.value)} />
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button variant="danger" type="submit" className='loginBtn' onClick={() => checkValidation(shortenLink, originalLink, auth.currentUser.email, setModalMessage, setShowModal)}>
                                Delete Now
                            </Button>
                        </div>

                    </div>
                </Container >
            </div >
        </>

    )
}