import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Col, Row, Toast, Modal, ToastContainer } from 'react-bootstrap';
import { auth, logInWithEmailAndPassword, SECRET_KEY, firebaseConfig } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import Turnstile, { useTurnstile } from "react-turnstile";
import CryptoJS from 'crypto-js';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { isMobile } from 'react-device-detect';
import "./Login.css";
import { toast } from "react-toastify";
import { TiInfoLarge } from "react-icons/ti";
import { FaChrome } from "react-icons/fa";
import { HiKey, HiOutlineKey } from "react-icons/hi2";

// As like KNOX Sercure Login
// const SECRET_KEY = 'XX2sJnT9(Q)<Z!TP19lP4Dc4@*>>QZ';

function TurnstileWidget({ setIsTurnstileCompleted }) {
  const turnstile = useTurnstile();
  return (
    <Turnstile
      // sitekey="1x00000000000000000000AA"
      sitekey="0x4AAAAAAAOvOY6OgNWdFWBQ"
      onVerify={(token) => {
        // console.log('Turnstile verification successful, token:', token);
        setIsTurnstileCompleted(true);
      }}
    // onVerify={(token) => {
    //   fetch("/login", {
    //     method: "POST",
    //     body: JSON.stringify({ token }),
    //   }).then((response) => {
    //     if (!response.ok) turnstile.reset();
    //   });
    // }}
    />
  );
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [isTurnstileCompleted, setIsTurnstileCompleted] = useState(false);

  // For Passwordless Sign In
  const [emailInput, setEmailInput] = useState("");
  const [show, setShow] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [showPasswordLessModal, setShowPasswordLessModal] = useState(false);
  const [passlessLoginSuccess, setPasslessLoginSuccess] = useState();

  const handlePasswordlessSignIn = async (email, event) => {
    event.preventDefault();
    const actionCodeSettings = {
      url: window.location.href, // This URL must be a URL of your app, and must be whitelisted in the Firebase Console.
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
        setPasslessLoginSuccess(true);
        setShowPasswordLessModal(false);
        setShowToast(true);
      });
      let ciphertext = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
      localStorage.setItem('ue', JSON.stringify(ciphertext));
      window.localStorage.setItem('emailForSignIn', email);
    } catch (err) {
      setPasslessLoginSuccess(false);
      setShowPasswordLessModal(false);
      setShowToast(true);
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    console.log('Loading:', loading);
    console.log('User:', user);
    if (loading) {
      return;
    }
    if (user) navigate("/home.adm");
  }, [user, loading]);

  if (isMobile) {
    return (
      <Container className='logincontainer align-items-center d-flex navless-container'>
        <div className="m-auto formwidth mx-3">
          <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />
          <h4>Mobile Currently Not Supported</h4>
          <p>You cannot access with mobile.</p>
        </div>
      </Container>
    );
  }

  const handleLogin = async (email, password, event) => {
    event.preventDefault();
    try {
      await logInWithEmailAndPassword(email, password).then(() => {
        // Encrypt
        let ciphertext = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
        localStorage.setItem('ue', JSON.stringify(ciphertext));
      });
    } catch (err) {
      if (err.message === 'Firebase: Error (auth/invalid-login-credentials).') {
        setModalMessage('Invalid email or password. Please check and try again.');
      } else if (err.message === 'Firebase: Error (auth/user-disabled).') {
        setModalMessage('User account is disabled.');
      } else if (err.message === 'Firebase: Error (auth/missing-password).') {
        setModalMessage('Password is required. Please enter your password.');
      } else if (err.message === 'Firebase: Error (auth/invalid-email).') {
        setModalMessage('Invalid email. Please check and try again.');
      } else {
        setModalMessage(err.message);
      }
      setShowModal(true);
    }
  };

  // const handleLogin = async () => {
  //   try {
  //     await logInWithEmailAndPassword(email, password).then(() => {
  //       // Encrypt
  //       const ciphertext = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
  //       localStorage.setItem('ue', JSON.stringify(ciphertext));
  //     });
  //   } catch (err) {
  //     if (err.message === 'Firebase: Error (auth/invalid-login-credentials).') {
  //       setModalMessage('Invalid email or password. Please check and try again.');
  //     } else if (err.message === 'Firebase: Error (auth/user-disabled).') {
  //       setModalMessage('User account is disabled.');
  //     } else if (err.message === 'Firebase: Error (auth/missing-password).') {
  //       setModalMessage('Password is required. Please enter your password.');
  //     } else if (err.message === 'Firebase: Error (auth/invalid-email).') {
  //       setModalMessage('Invalid email. Please check and try again.');
  //     } else {
  //       setModalMessage(err.message);
  //     }
  //     setShowModal(true);
  //   }
  // };

  return (

    <>

      <Container className='logincontainer align-items-center d-flex navless-container'>

        <div className="m-auto formwidth">

          <div className="d-flex">
            <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />
            <div className="statusbadge">
              <iframe src="https://status.go.nugmyanmar.org/badge?theme=white" width="250" height="30" frameBorder="0" scrolling="no"></iframe>
            </div>
          </div>

          <Form onSubmit={handleLogin} >

            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                placeholder="name@agency.nugmyanmar.org"
                type="email"
                onChange={e => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                onChange={e => setPassword(e.target.value)} />
            </Form.Group>

            <div className="d-grid gap-2">
              {/* <Button variant="danger" type="submit" className='loginBtn' onClick={() => logInWithEmailAndPassword(email, password)}>
              Sign in
            </Button> */}
              <Button variant="danger" type="submit" className='loginBtn' disabled={!isTurnstileCompleted} onClick={(event) => handleLogin(email, password, event)}>
                Sign in
              </Button>
            </div>

          </Form>

          {/* For Passwordless Sign In */}
          <div className="d-grid">
            <hr className="m-y:1" />
            <Button variant="danger" type="submit" className='loginBtn signinwithpasswordless' disabled={!isTurnstileCompleted} onClick={() => setShowPasswordLessModal(true)}>
              Sign in with SmartPass <HiOutlineKey />
            </Button>
          </div>


          {/* <Form.Group className="my-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" onChange={e => setEmail(e.target.value)} />
          </Form.Group>

          <Form.Group className="my-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" onChange={e => setPassword(e.target.value)} />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="danger" type="submit" className='loginBtn' disabled={!isTurnstileCompleted} onClick={() => handleLogin(email, password)}>
              Sign in
            </Button>
          </div> */}

          <div className="my-4">
            <TurnstileWidget setIsTurnstileCompleted={setIsTurnstileCompleted} />
          </div>

          <div className="recommended-browser d-flex bg-s0">
            <div className="d-flex justify-content-center bg-s70 info-pic">
              <TiInfoLarge color="grey" style={{margin: '10px 0 0 10px'}}/>
            </div>
            <div className="sm text-muted" style={{ padding: '8px 12px 8px 10px', width: '327px' }}>
              For the best experience, please use with the latest version of&nbsp;
              <span>
                <FaChrome />
              </span>
              &nbsp;Chrome browser.
            </div>
          </div>

        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{modalMessage}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* For Passwordless Sign In */}
        <Modal show={showPasswordLessModal} onHide={() => setShowPasswordLessModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>SmartPass Sign In <HiKey /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="name@agency.nugmyanmar.org"
                  value={emailInput}
                  onChange={e => setEmailInput(e.target.value)} />
                <Form.Text className="text-muted">
                  We'll send you a sign in link to this email.
                </Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPasswordLessModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={(event) => handlePasswordlessSignIn(emailInput, event)}>
              Send Sign In Link
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer
          className="p-3 position-absolute top-0 end-0"
          position={'top-end'}
          style={{ zIndex: 1 }}
        >
          <Toast show={showToast} onClose={toggleShowToast} delay={3000} autohide>
            <Toast.Header>
              <img
                src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                className="rounded me-2"
                width={20}
                alt="Go.NUG Logo"
              />
              <strong className="me-auto">Go.NUG</strong>
            </Toast.Header>
            <Toast.Body>{passlessLoginSuccess ? "Passwordless email is Successfully Sent" : "An error occurred: may be login with non signup email!"}</Toast.Body>          </Toast>
        </ToastContainer>

      </Container>
    </>


  );
}
export default Login;