import React, { Component } from 'react';
import LoadingSceen from '../Loading/Loading';
import './FormSuccess.css';
import { Button, Navbar } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class LocalComplaint extends Component {

    state = {
        "loading": true,
        "code": localStorage.getItem("code"),
        "message": localStorage.getItem("message"),
        "generatedURL": "https://go.nugmyanmar.org/" + localStorage.getItem("generatedURL")
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 2000);
    }

    render() {
        console.log(this.state.code);
        const goBackHome = () => {
            window.location = '/';
            localStorage.removeItem("code");
            localStorage.removeItem("message");
            localStorage.removeItem("generatedURL");
        };
        return (
            <>

                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                {this.state.loading === false ? (
                    <div className="App" >
                        <header className="bg animate mheight">
                            <div className='d-flex justify-content-between logoutbtn'>

                                <div>
                                    <button
                                        className="btn btn-outline-secondary m-3"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            window.location = '/home.adm';
                                        }}
                                    >
                                        Back to Home
                                    </button>
                                </div>

                                <div className='navbar'>
                                    <Navbar.Brand href="/home.adm">
                                        <img
                                            src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                                            width="40"
                                            height="40"
                                            className="d-inline-block align-center"
                                            alt="Go.NUG Logo"
                                        />
                                    </Navbar.Brand>
                                    <span className='slug text-muted'>/</span>
                                    <span className="slug text-muted">Transmission Status</span>
                                </div>
                            </div>

                            {this.state.code === "200" ? (
                                <div className="text-Box">
                                    <img src='https://nationalunitygovernment.b-cdn.net/assets/state/successmark.png' alt='Successful Mark' width={200} />
                                    <p className='h6 lh-lg'>
                                        Shorten URL Generated Successfully
                                    </p>
                                    <div className='h4 lh-lg'>
                                        {this.state.generatedURL === null ? (
                                            "Getting URL from server is failed! Please try again later."
                                        ) : (this.state.generatedURL)}
                                    </div>
                                    <Button variant="danger" className='copylinkbtn' onClick={() => {
                                        navigator.clipboard.writeText(this.state.generatedURL)
                                            .then(() => {
                                                toast.success('Custom link copied to clipboard as follow: ' + this.state.generatedURL);
                                            })
                                            .catch((error) => {
                                                toast.error('Error copying custom link to clipboard caused by: ' + error);
                                            });
                                    }}>Copy to Clipboard</Button>
                                </div>
                            ) : (
                                <div className="text-danger text-Box">
                                    <img src='https://nationalunitygovernment.b-cdn.net/assets/state/unsuccessmark.png' alt='Unsuccessful Mark' width={200} />
                                    <p className='h4 lh-lg'>
                                        The data transmission process encountered a failure.
                                    </p>
                                    {this.state.message === null ? (
                                        "No form filled out or server is down! Please try again later."
                                    ) : (this.state.message)}
                                </div>
                            )}
                        </header>
                    </div>
                ) : (
                    <LoadingSceen />
                )}
            </>
        );
    }
}