import React, { useState, useEffect } from "react";
import { Container, ListGroup, Badge, Button, Modal, Navbar, Tooltip } from 'react-bootstrap';
import { auth, generateShortLink, SECRET_KEY } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import "../Home/home.css";
import "./../../Global/shared-styles.css"
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { firebaseConfig } from "../../firebase.js";
import { constants } from "../../Global/constants.js"
import LoadingSceen from "../Loading/Loading.js";
import { MdMarkEmailUnread } from "react-icons/md";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function myanmardatetime(unixtimestamp) {
    const date = new Date(unixtimestamp);
    const options = {
        timeZone: 'Asia/Yangon',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };
    const myanmarDate = date.toLocaleString('en-US', options);
    return myanmarDate;
}

const handleCountdownComplete = () => {
    auth.signOut()
        .then(() => {
            // Logout successful
            console.log('User logged out.');
            localStorage.removeItem('ue');
            localStorage.clear();
            window.localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            // Handle logout error
            console.error('Error logging out:', error);
        });
};

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <handleCountdownComplete />;
    } else {
        // Render a countdown
        return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
    }
};

export default function MyAccount() {

    const currentYear = new Date().getFullYear();

    const [iamtxtonly, setIamtxtonly] = useState('');
    const [verifytxtonly, setVerifytxtonly] = useState('');
    const [tontxtonly, setTontxtonly] = useState('');
    const [govagencytxtonly, setGovagencytxtonly] = useState('');
    const [govagencyforprofile, setGovagencyforprofile] = useState('');

    const [currentuser, setCurrentUser] = useState(null);

    const [dataFatchComplete, setDataFatchComplete] = useState(false);

    const [lastActivity, setLastActivity] = useState(Date.now());

    const handleActivity = () => {
        setLastActivity(Date.now());
    };

    useEffect(() => {
        // Listen for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
        };
    }, []);

    const dataciphertext = JSON.parse(localStorage.getItem('ue'));

    // Decrypt
    const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
    const originalTextofEmail = bytes.toString(CryptoJS.enc.Utf8);

    const [user, cusloading, error] = useAuthState(auth);

    useEffect(() => {
        if (!cusloading && !user) {
            window.location.href = '/';
        }
    }, [user, cusloading]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                setCurrentUser(user);
            } else {
                // User is signed out.
                setCurrentUser(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        // Fetch user document with specific email from Firestore
        const fetchUser = async () => {
            const querySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', originalTextofEmail)));
            querySnapshot.forEach((doc) => {
                localStorage.setItem('ton', JSON.stringify(doc.data().individual));
                localStorage.setItem('verify', JSON.stringify(doc.data().verify));
                localStorage.setItem('iam', JSON.stringify(doc.data().isAdmin));

                const iam = doc.data().isAdmin;
                const verify = doc.data().verify;
                const ton = doc.data().individual;
                const govagency = doc.data().govagency;

                setIamtxtonly(iam ? 'Admin' : 'User');
                setVerifytxtonly(verify ? 'https://cdn.egov.nugmyanmar.org/assets/state/verifygov.png' : '');
                setGovagencyforprofile(govagency);
                setTontxtonly(ton ? 'Individual' : 'Organization');
                if (ton === false) {
                    setGovagencytxtonly(govagency);
                } else {
                    setGovagencytxtonly('');
                }
                setDataFatchComplete(true);
            });
        };

        fetchUser();
    }, [originalTextofEmail]); // Dependency array

    const iam = JSON.parse(localStorage.getItem('iam'));
    const verify = JSON.parse(localStorage.getItem('verify'));
    const ton = JSON.parse(localStorage.getItem('ton'));

    // const iamtxt = iam ? 'Admin' : 'User';
    // const verifytxt = verify ? 'https://cdn.egov.nugmyanmar.org/assets/state/verifygov.png' : '';
    // const tontxt = ton ? 'Individual' : 'Organization';

    return (
        <>
            {dataFatchComplete ? (
                <>

                    <div className="logincontainer">

                        <div className='d-flex justify-content-between logoutbtn'>

                            <div>
                                <Countdown
                                    className="m-3"
                                    date={Date.now() + 300000}
                                    renderer={renderer}
                                    onComplete={handleCountdownComplete}
                                />
                                <button
                                    className="btn btn-outline-secondary m-3"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        window.location = '/home.adm';
                                    }}
                                >
                                    Back to Home
                                </button>
                            </div>

                            <div className='navbar'>
                                <Navbar.Brand href="/home.adm">
                                    <img
                                        src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                                        width="40"
                                        height="40"
                                        className="d-inline-block align-center"
                                        alt="Go.NUG Logo"
                                    />
                                </Navbar.Brand>
                                <span className='slug text-muted'>/</span>
                                <span className="slug text-muted">My Account</span>
                            </div>

                        </div>
                        <Container className='align-items-center d-flex navless-container containerheight'>

                            <div className="m-auto formwidth">

                                <ListGroup>
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Email</div>
                                            <div className="fw-bold">{currentuser && currentuser.email}</div>
                                        </div>
                                        {
                                            tontxtonly === '' ? (
                                                <Badge bg="secondary mx-1 verifygovlogo" pill>Loading</Badge>
                                            ) : (
                                                verifytxtonly ? (
                                                    (auth.currentUser.emailVerified ? (
                                                        <>
                                                            <Badge bg="primary mx-1 verifygovlogo" pill>
                                                                Verified
                                                            </Badge>
                                                            < img src={verifytxtonly} height={40} className="verifygovlogo" />

                                                        </>
                                                    ) : (
                                                        <>
                                                            <Badge bg="danger mx-1 verifygovlogo" pill>
                                                                <MdMarkEmailUnread />
                                                                Not Verified
                                                            </Badge>
                                                            < img src={verifytxtonly} height={40} className="verifygovlogo" />

                                                        </>
                                                    ))

                                                ) : (
                                                    <Badge bg="danger mx-1 verifygovlogo" pill>
                                                        Not Verified
                                                    </Badge>
                                                )
                                            )
                                        }
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Account Type</div>
                                            <div className="fw-bold">{tontxtonly}</div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Government Agency</div>
                                            <div className="fw-bold">{govagencyforprofile}</div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Account Created Date</div>
                                            <div className="fw-bold">{currentuser && myanmardatetime(currentuser.metadata.creationTime)}</div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Last Sign In Time</div>
                                            <div className="fw-bold">{currentuser && myanmardatetime(currentuser.metadata.lastSignInTime)}</div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                <ListGroup className="my-3">
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div>Version</div>
                                            <div className="fw-bold">{constants.appversion}</div>
                                        </div>
                                        <Badge
                                            className="hover-pointer"
                                            bg="secondary mx-1 verifygovlogo"
                                            onClick={() => { window.open('https://guide.go.nugmyanmar.org/updates-and-releases', '_blank'); }}
                                            pill>
                                            What New?
                                        </Badge>
                                    </ListGroup.Item>
                                </ListGroup>

                                <hr className="m-y:6" />

                                <div className="d-grid">
                                    <Button variant="outline-secondary" type="submit" className='loginBtn deleteBtn' onClick={handleCountdownComplete}>
                                        Logout
                                    </Button>
                                </div>

                            </div>
                        </Container >
                        <div className="footer">
                            <p className="text-muted">© {currentYear} NUG Myanmar </p>
                        </div>
                    </div >
                </>
            ) : (
                <LoadingSceen />
            )
            }
        </>

    )
}