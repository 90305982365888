import React, { useState } from "react";
import { Container, Form, Button, Modal, Navbar } from 'react-bootstrap';
import { accountRegistration, auth, SECRET_KEY } from "../../firebase.js";
import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import "./Accreg.css";
import "../Home/home.css";
import "./../../Global/shared-styles.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Accreg() {
  const [name, setName] = useState("");
  const [govagency, setGovagency] = useState("");
  const [verify, setVerify] = useState(false);
  const [individual, setIndividual] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [createduseremail, setCreatedUserEmail] = useState("");
  const [createduserpassword, setCreatedUsersPassword] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState('');

  // useEffect(() => {
  //   setCurrentUserIsAdmin(localStorage.getItem('iam'));
  // }, []);

  const currentUserIsAdmin = localStorage.getItem('iam');
  const dataciphertext = JSON.parse(localStorage.getItem('ue'));
  const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
  const originalTextofCurrentUserEmail = bytes.toString(CryptoJS.enc.Utf8);

  const handleCountdownComplete = () => {
    auth.signOut()
      .then(() => {
        // Logout successful
        console.log('User logged out.');
        localStorage.removeItem('ue');
        localStorage.clear();
        window.localStorage.clear();
        window.location = '/';
      })
      .catch((error) => {
        // Handle logout error
        console.error('Error logging out:', error);
      });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <handleCountdownComplete />;
    } else {
      // Render a countdown
      return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
    }
  };

  const handleSignup = async () => {
    try {
      await accountRegistration(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword, originalTextofCurrentUserEmail)
        .then((result) => {
          setModalMessage(result.message);
          setShowModal(true);
        });
    } catch (err) {
      setModalMessage(err.message);
      setShowModal(true);
    }
  };

  if (currentUserIsAdmin === 'true') {
    return (

      <>
        <div className="d-flex justify-content-between logoutbtn">
          <div>
            <Countdown
              className="m-3"
              date={Date.now() + 300000}
              renderer={renderer}
              onComplete={handleCountdownComplete}
            />
            <button
              className="btn btn-outline-secondary m-3"
              onClick={(event) => {
                event.preventDefault();
                window.location = '/home.adm';
              }}
            >
              Back to Home
            </button>
          </div>

          <div className='navbar'>
            <Navbar.Brand href="/home.adm">
              <img
                src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png"
                width="40"
                height="40"
                className="d-inline-block align-center"
                alt="Go.NUG Logo"
              />
            </Navbar.Brand>
            <span className='slug text-muted'>/</span>
            <span className="slug text-muted">User Create</span>
            <button className="btn btn-outline-secondary customBtnBg" onClick={() => { window.location.href = '/go.userlists.adm'; }}>User Lists</button>
          </div>
        </div>

        <Container className='acccreatecontainer align-items-center d-flex navless-container'>

          <div className="m-auto formwidth">
            <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />

            <Form.Group className="my-3" controlId="formBasicName">
              <Form.Label>Name *</Form.Label>
              <Form.Control required type="text" onChange={e => setName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gov Agency *</Form.Label>
              <Form.Select required onChange={e => setGovagency(e.target.value)}>
                <option value="MOD">Ministry of Defence</option>
                <option value="MOH">Ministry of Health</option>
                <option value="MOPFI">Ministry of Planning, Finance and Investment</option>
                <option value="MOFA">Ministry of Foreign Affairs</option>
                <option value="MOE">Ministry of Education</option>
                <option value="MOHAI">Ministry of Home Affairs and Immigration</option>
                <option value="MOFUA">Ministry of Federal Union Affairs</option>
                <option value="MOHADM">Ministry of Humanitarian Affairs and Disaster Management</option>
                <option value="MOHR">Ministry of Human Rights</option>
                <option value="MONREC">Ministry of Natural Resources and Environmental Conservation</option>
                <option value="MOIC">Ministry of International Cooperation</option>
                <option value="MOWYCA">Ministry of Women, Youths and Children Affairs</option>
                <option value="MOL">Ministry of Labour</option>
                <option value="MOJ">Ministry of Justice</option>
                <option value="MOCIT">Ministry of Communications, Information & Technology</option>
                <option value="MOEE">Ministry of Electricity & Energy</option>
                <option value="MOC">Ministry of Commerce</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 d-flex justify-content-between">
              <Form.Check // prettier-ignore
                type="switch"
                id="verify-switch"
                label="Verify Gov"
                checked={verify}
                unchecked='unverify'
                onChange={e => setVerify(e.target.checked)}
              />

              <Form.Check // prettier-ignore
                type="switch"
                id="individual-switch"
                label="Individual"
                checked={individual}
                onChange={e => setIndividual(
                  e.target.checked
                )}
              />

              <Form.Check // prettier-ignore
                type="switch"
                id="isadmin-switch"
                label="Is Admin"
                checked={isadmin}
                onChange={e => setIsadmin(e.target.checked)}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>Email address *</Form.Label>
              <Form.Control required type="email" onChange={e => setCreatedUserEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label>Password *</Form.Label>
              <Form.Control required type="password" onChange={e => setCreatedUsersPassword(e.target.value)} />
            </Form.Group>

            <div className="d-grid gap-2">
              {/* <Button variant="danger" type="submit" className='loginBtn' onClick={() => logInWithEmailAndPassword(email, password)}>
                Sign in
              </Button> */}
              <Button variant="danger" type="submit" className='loginBtn' onClick={() => handleSignup(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword)}>
                Register Account
              </Button>
            </div>

            {/* <div className="my-4">
              <TurnstileWidget setIsTurnstileCompleted={setIsTurnstileCompleted} />
            </div> */}

          </div>

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Info</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>{modalMessage}</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        </Container>
      </>

    );
  } else {
    return (

      <div className="App404" >
        <header className="header404 bg">
          <img src='https://cdn.egov.nugmyanmar.org/assets/state/linkbroke.png' alt='Link Broke IMG' width={200} />
          {/* <img src={concernloading} className="App-logo" alt="Loading" /> */}
          <h3 className='text-dark font-weight-bold'>
            Ohh no.., You do not have access to this page.
          </h3>
          <p className='text-dark'>
            For any additional support or inquiries, kindly reach out to the respective agency.
          </p>
          <p className='text-dark'>
            Thank you for your understanding.</p>
          <button className="btn btn-outline-secondary m-3" onClick={() => { window.location.href = '/'; }}>
            Back to Home
          </button>
        </header>

      </div>
    );
  }


}
export default Accreg;