// import React from 'react';
// import { Spinner, Container } from "react-bootstrap";
// import './Loading.css';

// export default function LoadingSceen() {
//     return (
//         <Container fluid className='loading'>
//             <div style={{ position: 'relative' }}>
//                 <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={1} alt="Go.NUG Logo" className='loadinglogo' />
//                 <Spinner
//                     animation="grow"
//                     variant="danger"
//                     style={{
//                         position: 'absolute',
//                         top: '40%',
//                         left: '40%',
//                         transform: 'translate(-40%, -40%)'
//                     }} />
//             </div>
//         </Container>
//     )
// }

import React from 'react';
import { Spinner, Container } from "react-bootstrap";
import './Loading.css';

export default function LoadingSceen() {
    return (
        <Container fluid className='loading d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
            <div style={{ position: 'relative' }}>
                <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={100} alt="Go.NUG Logo" className='loadinglogo' />
                <Spinner
                    animation="grow"
                    variant="danger"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }} />
            </div>
        </Container>
    )
}