import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Login from './components/LogIn/Login';
import SmartPass from './components/LogIn/SmartPassLogin';
import Home from './components/Home/home';
import Delete from './components/Delete/delete';
import Lists from './components/List/list';
import FormSuccess from './components/FormSuccess/FormSuccess';
import reportWebVitals from './reportWebVitals';
import Accreg from './components/AccountRegistration/Accreg';
import MyAcc from './components/MyAccount/myaccount';
import UserLists from './components/UserList/userlist';
import { StatisticsReport } from './components/StatisticReport/statisticreport';
import WhoIS from './components/WHOIS/whois';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<SmartPass />} />
        <Route path='myaccount.adm' element={<MyAcc />} />
        <Route path='go.usercreation.adm' element={<Accreg />} />
        <Route path='home.adm' element={<Home />} />
        <Route path='delete.adm' element={<Delete />} />
        <Route path='lists.adm' element={<Lists />} />
        <Route path='go.userlists.adm' element={<UserLists />} />
        <Route path='whois' element={<WhoIS />} />
        <Route path='transmissionstatus' element={<FormSuccess />} />
        <Route path='statistics' element={<StatisticsReport />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
