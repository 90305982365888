import React from 'react';
import { Container } from 'react-bootstrap';

const MobileNotSupport = () => (
    <Container className='logincontainer align-items-center d-flex navless-container'>
        <div className="m-auto formwidth mx-3">
            <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nugonlylogo.png" width={80} className="godotnuglogo align-items-center" />
            <h4>Mobile Currently Not Supported</h4>
            <p>You cannot access with mobile.</p>
        </div>
    </Container>
);

export default MobileNotSupport;
