import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { getFirestore, collection, orderBy, setDoc, deleteDoc, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import emailjs from '@emailjs/browser';

// As like KNOX Sercure Login
const SECRET_KEY = 'XX2sJnT9(Q)<Z!TP19lP4Dc4@*>>QZ';

/* Dev Config */

// const firebaseConfig = {
//     apiKey: "AIzaSyBYYS0ziPaTK856psaly5YUkzR_dlfouM8",
//     authDomain: "gonugmyanmar.firebaseapp.com",
//     projectId: "gonugmyanmar",
//     storageBucket: "gonugmyanmar.appspot.com",
//     messagingSenderId: "863569362043",
//     appId: "1:863569362043:web:dda137170f5acbc46976de",
//     measurementId: "G-JYQW9KLZCM"
// };

/* Prod Config */
const firebaseConfig = {
    apiKey: "AIzaSyCXxNVxVEYMUPP1oF-aatik7-yrKOZk8dY",
    authDomain: "gonugmm.firebaseapp.com",
    projectId: "gonugmm",
    storageBucket: "gonugmm.appspot.com",
    messagingSenderId: "1012307687730",
    appId: "1:1012307687730:web:886f201e6461228a26bf2b",
    measurementId: "G-L5ND6Q0HR8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

const colRef = collection(db, "goto");

const colforStatRef = collection(db, "linkStatistics");

// if (JSON.parse(localStorage.getItem('ue')) == null) {
//     signOut(auth);
//     localStorage.clear();
//     window.location = '/';
// }

const accountRegistration = async (name, govagency, verify, individual, isadmin, createduseremail, createduserpassword, originalTextofCurrentUserEmail) => {
    console.log(name, govagency, verify, individual, isadmin, createduseremail, createduserpassword);
    try {
        // await createUserWithEmailAndPassword(auth, createduseremail, createduserpassword);
        const userCredential = await createUserWithEmailAndPassword(auth, createduseremail, createduserpassword);
        setDoc(doc(db, "users", createduseremail), {
            "name": name,
            "email": createduseremail,
            "govagency": govagency,
            "verify": verify,
            "individual": individual,
            "isAdmin": isadmin,
            "approvedby": originalTextofCurrentUserEmail,
            "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
            "serverTimestamp": new Date()
        });
        await updateProfile(userCredential.user, { displayName: name });
        sendEmailVerification(userCredential.user);
        return { code: 200, message: "Successfully Submitted!" };
    } catch (err) {
        console.error(err);
        throw err;
    }
};

// const sendEmailVerificationAgain = () => {
//     sendEmailVerification(auth.currentUser.email);
// }

const sendEmailVerificationAgain = () => {
    return sendEmailVerification(auth.currentUser)
        .then(() => ({ code: 200, message: "Verification email sent!" }))
        .catch((error) => {
            console.error('Failed to send verification email: ', error);
            return { code: 400, message: "Failed to send verification email" };
        });
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
        // await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
        //     const userEmail = userCredential.user.email;
        //     const userName = userCredential.user.name;
        //     return { userEmail, userName };
        // });
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;

        if (user) {
            console.log('User authenticated:', user.email);
        } else {
            console.error('User authentication failed.');
            alert('User authentication failed.');
        }
    } catch (err) {
        console.error(err);
        // alert(err.message);
        throw err;
    }
};

const logout = () => {
    signOut(auth);
};

// const generateShortLink = async (linkTitle, originalLink, customLink) => {
//     try {
//         var idcode = '';

//         const uuid = 'xxxxxxxxxx'.replace(/x/g, () => Math.floor(Math.random() * 16).toString(16));

//         var duuid = '';

//         if (customLink !== '') {
//             duuid = customLink;
//             await getDoc(doc(db, 'goto', duuid)).then(async (docSnapshot) => {
//                 if (docSnapshot.exists()) {
//                     console.log('Document data:', docSnapshot.data());
//                     return { code: 400, message: "This custom link is already exist!", customid: customLink };
//                 } else {
//                     // Use setDoc instead of addDoc
//                     await setDoc(doc(colRef, duuid), {
//                         "name": linkTitle,
//                         "addedby": auth.currentUser.email,
//                         "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
//                         "url": originalLink,
//                         "active": true,
//                         "clicks": 0,
//                         "isprotected": false,
//                         "password": "",
//                     }).then(() => {
//                         idcode = duuid;  // Replace 'yourDocumentId' with the actual document ID
//                         // emailjs.init('JOrRwsDD0HqgPL-F9');
//                         var templateParams = {
//                             linkTitle: linkTitle,
//                             RECIPIENT_EMAIL: auth.currentUser.email,
//                             originalLink: originalLink,
//                             idcode: idcode
//                         };
//                         emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
//                             console.log('SUCCESS!', response.status, response.text);
//                         }, function (error) {
//                             console.log('FAILED...', error);
//                         });
//                     });

//                     return { code: 200, message: "Successfully Submitted!", customid: idcode };
//                 }
//             });
//         } else {
//             duuid = uuid.substring(0, 8);
//             // Use setDoc instead of addDoc
//             await setDoc(doc(colRef, duuid), {
//                 "name": linkTitle,
//                 "addedby": auth.currentUser.email,
//                 "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
//                 "url": originalLink,
//                 "active": true,
//                 "clicks": 0,
//                 "isprotected": false,
//                 "password": "",
//             }).then(() => {
//                 idcode = duuid;  // Replace 'yourDocumentId' with the actual document ID
//                 // emailjs.init('JOrRwsDD0HqgPL-F9');
//                 var templateParams = {
//                     linkTitle: linkTitle,
//                     RECIPIENT_EMAIL: auth.currentUser.email,
//                     originalLink: originalLink,
//                     idcode: idcode
//                 };
//                 emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
//                     console.log('SUCCESS!', response.status, response.text);
//                 }, function (error) {
//                     console.log('FAILED...', error);
//                 });
//             });

//             return { code: 200, message: "Successfully Submitted!", customid: idcode };
//         }

//         // if (customLink !== '') {
//         //     await getDoc(doc(db, 'goto', customLink)).then((docSnapshot) => {
//         //         if (docSnapshot.exists()) {
//         //             console.log('Document data:', docSnapshot.data());
//         //             return { code: 400, message: "This custom link is already exist!" };
//         //         } else {

//         //             // Use setDoc instead of addDoc
//         //             await setDoc(doc(colRef, duuid), {
//         //                 "name": linkTitle,
//         //                 "addedby": auth.currentUser.email,
//         //                 "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
//         //                 "url": originalLink,
//         //                 "active": true,
//         //                 "clicks": 0,
//         //                 "isprotected": false,
//         //                 "password": "",
//         //             }).then(() => {
//         //                 idcode = duuid;  // Replace 'yourDocumentId' with the actual document ID
//         //                 // emailjs.init('JOrRwsDD0HqgPL-F9');
//         //                 var templateParams = {
//         //                     linkTitle: linkTitle,
//         //                     RECIPIENT_EMAIL: auth.currentUser.email,
//         //                     originalLink: originalLink,
//         //                     idcode: idcode
//         //                 };
//         //                 emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
//         //                     console.log('SUCCESS!', response.status, response.text);
//         //                 }, function (error) {
//         //                     console.log('FAILED...', error);
//         //                 });
//         //             });

//         //             return { code: 200, message: "Successfully Submitted!", customid: idcode };
//         //         }
//         //     });
//         // }

//     } catch (err) {
//         console.error(err.message);
//         return { code: 400, message: err.message };
//     }
// };

const generateShortLink = async (linkTitle, originalLink, customLink) => {
    try {
        var idcode = '';
        const uuid = 'xxxxxxxxxx'.replace(/x/g, () => Math.floor(Math.random() * 16).toString(16));
        var duuid = '';

        if (customLink !== '') {
            duuid = customLink;
            const docSnapshot = await getDoc(doc(db, 'goto', duuid));
            if (docSnapshot.exists()) {
                console.log('Document data:', docSnapshot.data());
                return { code: 400, message: "This custom link is already exist!", customid: customLink };
            } else {
                let currentTimeStamp = new Date();
                await setDoc(doc(colRef, duuid), {
                    "name": linkTitle,
                    "addedby": auth.currentUser.email,
                    "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
                    "serverTimestamp": new Date(),
                    "url": originalLink,
                    "active": true,
                    "clicks": 0,
                    "isprotected": false,
                    "password": "",
                    "activityLog": [
                        {
                            [currentTimeStamp]: {
                                "useremail": auth.currentUser.email,
                                "timestamp": new Date(),
                                "activity": "Link Created"
                            }
                        }
                    ]
                });
                await setDoc(doc(colforStatRef, duuid), {
                    "statistics": [
                        {
                            [currentTimeStamp]: {
                                "useremail": auth.currentUser.email,
                                "timestamp": new Date(),
                                "activity": "Created"
                            }
                        }
                    ]
                });
                idcode = duuid;
                var templateParams = {
                    linkTitle: linkTitle,
                    RECIPIENT_EMAIL: auth.currentUser.email,
                    originalLink: originalLink,
                    idcode: idcode
                };
                await emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                }, function (error) {
                    console.log('FAILED...', error);
                });
                return { code: 200, message: "Successfully Submitted!", customid: idcode };
            }
        } else {
            let currentTimeStamp = new Date();
            duuid = uuid.substring(0, 8);
            await setDoc(doc(colRef, duuid), {
                "name": linkTitle,
                "addedby": auth.currentUser.email,
                "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
                "serverTimestamp": new Date(),
                "url": originalLink,
                "active": true,
                "clicks": 0,
                "isprotected": false,
                "password": "",
                "activityLog": [
                    {
                        [currentTimeStamp]: {
                            "useremail": auth.currentUser.email,
                            "timestamp": new Date(),
                            "activity": "Link Created"
                        }
                    }
                ]
            });
            await setDoc(doc(colforStatRef, duuid), {
                // "statistics": [
                //     {
                //         [currentTimeStamp]: {
                //             "useremail": auth.currentUser.email,
                //             "timestamp": new Date(),
                //             "activity": "Created"
                //         }
                //     }
                // ]
                [currentTimeStamp]: {
                    "useremail": auth.currentUser.email,
                    "timestamp": new Date(),
                    "activity": "Created"
                }
            });
            idcode = duuid;
            var templateParams = {
                linkTitle: linkTitle,
                RECIPIENT_EMAIL: auth.currentUser.email,
                originalLink: originalLink,
                idcode: idcode
            };
            await emailjs.send("mocitmail_n5xxnfy", "template_iviynab", templateParams, "JOrRwsDD0HqgPL-F9").then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
            return { code: 200, message: "Successfully Submitted!", customid: idcode };
        }
    } catch (err) {
        console.error(err.message);
        return { code: 400, message: err.message };
    }
};

const getShortenLinksData = async (originalTextofEmail) => {
    try {
        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const shortenlinksDb = collection(db, "goto");
                    // const currentUserEmail = user.email.toString();
                    const currentUserEmail = originalTextofEmail;
                    const queryData = query(shortenlinksDb, where("addedby", "==", currentUserEmail), orderBy("timestamp"));
                    const getData = await getDocs(queryData);
                    const dataList = getData.docs.map((doc) => {
                        return {
                            id: doc.id, // This line gets the document ID
                            ...doc.data(), // This line gets the document data
                        };
                    });
                    unsubscribe(); // Unsubscribe to onAuthStateChanged once we have the data
                    resolve(dataList);
                } else {
                    console.error('User not authenticated');
                    unsubscribe(); // Unsubscribe to onAuthStateChanged if user is not authenticated
                    resolve([]); // Resolve with an empty array
                }
            }, (error) => {
                console.error('Error in onAuthStateChanged:', error);
                reject(error);
            });
        });
    } catch (err) {
        console.error(err.message);
        return [];
    }
};

const getUserListData = async (currentUserIsAdmin) => {
    try {
        const usersDb = collection(db, "users");
        const queryData = query(usersDb, orderBy("timestamp"));
        const getData = await getDocs(queryData);
        const dataList = getData.docs.map((doc) => {
            return {
                id: doc.id, // This line gets the document ID
                ...doc.data(), // This line gets the document data
            };
        });
        return dataList;
        // if (currentUserIsAdmin === 'true') {
        //     const usersDb = collection(db, "users");
        //     const queryData = query(usersDb, orderBy("timestamp"));
        //     const getData = await getDocs(queryData);
        //     const dataList = getData.docs.map((doc) => {
        //         return {
        //             id: doc.id, // This line gets the document ID
        //             ...doc.data(), // This line gets the document data
        //         };
        //     });
        //     return dataList;
        // } else {
        //     console.error('You are not admin!');
        //     return [];
        // }
    } catch (err) {
        console.error(err.message);
        return [];
    }
};

// const getShortenLinksData = async () => {
//     try {
//         return new Promise((resolve, reject) => {
//             const unsubscribe = onAuthStateChanged(auth, async (user) => {
//                 if (user) {
//                     const shortenlinksDb = collection(db, "goto");
//                     const currentUserEmail = user.email.toString();
//                     const queryData = query(shortenlinksDb, where("addedby", "==", currentUserEmail));
//                     const getData = await getDocs(queryData);
//                     // const dataList = getData.docs.map((doc) => doc.data());
//                     const dataList = getData.docs.map((doc) => {
//                         return {
//                             id: doc.id, // This line gets the document ID
//                             ...doc.data(), // This line gets the document data
//                         };
//                     });
//                     unsubscribe(); // Unsubscribe to onAuthStateChanged once we have the data
//                     resolve(dataList);
//                 } else {
//                     console.error('User not authenticated');
//                     unsubscribe(); // Unsubscribe to onAuthStateChanged if user is not authenticated
//                     resolve([]); // Resolve with an empty array
//                 }
//             }, (error) => {
//                 console.error('Error in onAuthStateChanged:', error);
//                 reject(error);
//             });
//         });
//     } catch (err) {
//         console.error(err.message);
//         return [];
//     }
// };

const deleteShortLink = async (shortenLink, originalLink, userEmail) => {

    var rescode = '';
    var resmessage = '';

    const documentSnapshot = await getDoc(doc(db, 'goto', shortenLink.substring(26)));
    if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        const dataValue = data && data.url;
        console.log(dataValue);

        if (data.url === originalLink && data.addedby === userEmail) {
            console.log(shortenLink);
            await deleteDoc(doc(db, "goto", shortenLink.substring(26))).then(() => {
                console.log('Document successfully deleted!');
                rescode = '200';
                resmessage = "Successfully Deleted!";
            }).catch((error) => {
                console.error('Error deleting document:', error);
                rescode = '400';
                resmessage = "An error occur! caused by " + error.message;
            });
        } else if (data.url !== originalLink && data.addedby === userEmail) {
            rescode = '400';
            resmessage = "Original link is missing!"
        } else {
            rescode = '400';
            resmessage = "You don't have permission to delete this url!";
        }

    } else {
        rescode = '400';
        resmessage = "We didn't find any URL like this!";
    }

    return { code: rescode, message: resmessage }

}

const removeShortenLinksData = async (shortenLinkId) => {
    await deleteDoc(doc(db, "goto", shortenLinkId)).then(() => {
        console.log("Successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

export {
    auth,
    db,
    signInWithEmailAndPassword,
    logInWithEmailAndPassword,
    accountRegistration,
    logout,
    generateShortLink,
    deleteShortLink,
    getShortenLinksData,
    removeShortenLinksData,
    SECRET_KEY,
    firebaseConfig,
    getUserListData,
    sendEmailVerificationAgain
};